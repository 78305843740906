import React, { useEffect, useRef, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import DataTable from './DataTable';
import { IoCopyOutline } from "react-icons/io5";
import CopyToClipboard from 'react-copy-to-clipboard';

import FeedbackModal from './FeedbackModal';

const containerStyle = {
  width: '100%',
  overflowY: 'auto',
  border: '1px solid #006bac',
  paddingInline: '10px',
  fontSize: '1.25rem',
  textAlign: 'left'
};

const btn = {
  background: '#da7700',
  color: '#ffffff',
  fontWeight: 600,
  width: '8rem',
  height: '3rem',
  fontSize: '1.2rem',
  border: 'none',
  borderRadius: '4px',
  marginBottom: '1rem',
  cursor: 'pointer'
};

const myStyle = {
  backgroundColor: '#e5c3c3',
  border: '1px solid #EF4444',
  color: '#666666',
  fontWeight: 400,
  fontSize: '1.25rem',
  borderRadius: '6px'
};

const errorStyle = {
  border: '1px solid #006bac',
  height: '210px',
  textAlign: 'center',
  marginBottom: '25px',
  padding: '10px',
  fontSize: '20px',
};

const iconContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};
const iconStyle = {
  fontSize: '1rem',
  color: '#da7700',
  marginRight: '5px',
};

//code added for feedback by ankur
// const buttonStyle = {
//   background: '#da7700',
//   color: '#ffffff',
//   fontWeight: 600,
//   width: '8rem',
//   height: '3rem',
//   fontSize: '1.2rem',
//   border: 'none',
//   borderRadius: '4px',
//   marginBottom: '1rem',
//   cursor: 'pointer',
//   marginRight: '1rem' // Added for spacing between buttons
// };

const feedbackbutton = {
  background: '#da7700',
  color: '#ffffff',
  fontWeight: 600,
  width: '100px',
  height: '25px',
  fontSize: '12px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  marginRight: '1rem',
  marginTop: '5px',
  letterSpacing: '2px'
}



const feedbackdiv = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row-reverse',
  background: '#fff',
  position: 'sticky',
  width: '100%',
  top: 0
}

// end of feedback

const TextArea = ({ responseText, regenerateHandler, isLoading, isAdminUser }) => {

  const paraRef = useRef(null);
  const [buttonText, setButtonText] = useState('Copy');
  const [showModal, setShowModal] = useState(false);
  const [feedbackId, setFeedbackId] = useState('');
  const [showFeedButton, setShowFeedButton] = useState(true)
  const [feedbackContent, setFeedbackContent] = useState('');

  const { content, uniqueId, lectin, doi, products } = responseText;
  const isBypassUser = isAdminUser;

  useEffect(() => {
    setShowFeedButton(true);
  }, [responseText]);

  // Check if content exists and is not undefined
  const cleanedContent = content ? content.replace(/<br\s*\/?>/g, '\n') : '';
  const UniqueRef = uniqueId ? uniqueId : '';
  const handleCopy = () => {
    setButtonText('Copied');
    setTimeout(() => {
      setButtonText('Copy');
    }, 2000); // Reset button text after 2 seconds
  };
  const OpenFeedBackForm = (uniqueId) => {
    setFeedbackId(uniqueId);
    setShowModal(true);
    setFeedbackContent(cleanedContent);
  };
  const closeModal = () => {
    setShowModal(false);
  };



  // Check if error occurred
  if (responseText && responseText.error) {
    return (
      <>
        <div style={errorStyle}>
          <div style={myStyle}>
            Oops! Something went wrong. The engineering team has been alerted to fix the issue. Please try again later.
          </div>
        </div>
        <button style={btn} onClick={regenerateHandler}>Regenerate</button>
      </>
    );
  }

  // Check if responseText is undefined or null
  if (!responseText) {
    return (
      <div className="answer-main">
        <div style={{ color: '#006bac', fontSize: '1.25rem', padding: '10px' }}>
          ANSWER
        </div>

        {isLoading && <span style={{}}>
          <ThreeDots
            visible={true}
            height="50"
            width="50"
            color="#666666"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          /> </span>}
      </div>
    );
  }

  return (
    <div className="answer-main">
      <div style={containerStyle}>
        <CopyToClipboard text={cleanedContent} onCopy={handleCopy}>
          <div style={feedbackdiv}>
            <div style={iconContainerStyle}>
              <IoCopyOutline style={iconStyle} title={buttonText} />
              <span style={{ fontSize: '16px' }}>{buttonText}</span>
            </div>
            {isBypassUser && uniqueId && showFeedButton && (
              <button style={feedbackbutton} onClick={() => OpenFeedBackForm(UniqueRef)}>Feedback</button>
            )}
          </div>
        </CopyToClipboard>

        {content && (
          <>
            <div>
              <div>
                {cleanedContent.split('\n').map((paragraph, index) => (
                  <p ref={paraRef} key={index} style={{ marginBlock: '10px', color: "#666", lineHeight: 1.4 }}>
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
          </>
        )}

        {lectin && lectin.length > 0 && (
          <div style={{ marginBottom: "1rem" }}>
            <strong style={{ color: '#666' }}>Lectin:</strong> <span style={{ color: '#666' }}>{lectin.join(', ')}</span>
          </div>
        )}
        {doi && doi.length > 0 && (
          <div style={{ marginBottom: "1rem" }}>
            <strong style={{ color: '#666' }}>DOI:</strong> {Array.isArray(doi) ? doi.map((doii, index) => (
              <div key={index} style={{ marginBottom: "0.7rem", marginTop: "0.7rem" }}>
                <a style={{ color: "#006bac" }} href={`https://doi.org/${doii}`} target="_blank" rel="noopener noreferrer">{doii}</a>
              </div>
            )) : (
              <div style={{ marginBottom: "0.7rem" }}>
                <a style={{ color: "#006bac" }} href={`https://doi.org/${doi}`} target="_blank" rel="noopener noreferrer">{doi}</a>
              </div>
            )}
          </div>
        )}


        {products && products.length > 0 && (
          <div>
            {Array.isArray(products) && products[0] && products[0].length >= 2 && (
              <strong style={{ color: '#666' }}>Products:</strong>
            )}
            {Array.isArray(products) && products[0] && products[0].length >= 2 ? (
              products.map((product, index) => (
                <div key={index}>
                  <div style={{ marginBottom: "0.7rem", marginTop: "0.7rem" }}>
                    <a style={{ color: "#006bac" }} href={product[1]} target='_blank' rel='noopener noreferrer'>
                      {product[0]}
                    </a>
                  </div>
                </div>
              ))
            ) : null}
          </div>
        )}
        {products && products.length > 0 && (
          <DataTable tableData={products} />
        )}
      </div>
      {showModal && <FeedbackModal content={feedbackContent} showModal={showModal} closeModal={closeModal} feedbackId={feedbackId} setShowFeedButton={setShowFeedButton} showFeedButton={showFeedButton} />}
    </div>
  );
};

export default TextArea;
