import React, { useEffect, useState } from 'react';

const btn = {
  background: '#da7700',
  color: '#ffffff',
  fontWeight: 600,
  width: '40%',
  height: '3rem',
  fontSize: '1.2rem',
  border: 'none',
  borderRadius: '4px',
  marginBottom: '1rem',
  cursor: 'pointer',
  marginBottom: '1rem',
};

const EmailPopUp = ({ onSubmit, APIerror, setAPIError }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  
  useEffect(() => {
    if(APIerror) {
      setAPIError(APIerror);
    }
  }, [APIerror]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateEmail(email)) {
      setError('');
      onSubmit(email);
    } else {
      setError('Please enter a valid email address.');
      setAPIError('');
    }
  };

  return (
    <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Please enter your email to start conversation</h5>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-5">
                <input type="email" className={`form-control ${error && 'is-invalid'}`} id="email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%',height:'3rem', marginTop:'25px' }} placeholder='Email address' /> 
                
              </div>
              {error && <p style={{position: 'absolute', top: '52%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%'}} className="text-danger">{error}</p>}
              {APIerror && <p style={{position: 'absolute', top: '52%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%'}} className="text-danger">{APIerror}</p>}
              
              <div className="mt-3">
                <button type="submit" className="btn btn-primary" style={btn}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPopUp;
