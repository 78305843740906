// SearchBar.js
import React, { useRef, useState,useEffect } from 'react';
import search_icon from '../images/search.png';
import { RxCross1 } from "react-icons/rx";


const crossStyle = {
  position: 'absolute',
    right: '14px',
    bottom: '8px',
    cursor:'pointer'
}

const SearchBar = ({ value, setInputValue, onChange, onClick, disabled, showPlaceholder }) => {

  const [showText,setShowText] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false);

  const inputRef = useRef()

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick();
      setIsDisabled(true);
    }
  };

 const handleClick = () =>{
    setShowText(false)
    setIsDisabled(false)
  }

  const handleWindowClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowText(true);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  const handleInputChange = (event) => {
    setShowText(event.target.value === '');
    setIsDisabled(false);
    onChange(event);
  };
  
  const handleCrossClick = () => {
    setInputValue('');
    setIsDisabled(false);
  }

  return (
    <div className="search-middle">
      <div className="search-middle-inner">
        <div className="input-placeholder" ref={inputRef}>
          <input
            onClick={handleClick}
            type="text"
            required
            value={value}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
          {value && !isDisabled && (
            <RxCross1 style={crossStyle} size="24px" onClick={handleCrossClick} />
          )}
          
          {showPlaceholder && showText && (
            <div className="placeholderr">
              ask glyco<span>GPT</span>
            </div>
          )}
        </div>
        <button onClick={onClick}>
          <img src={search_icon} alt="search icon" />
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
