import React from 'react';

const htmlEntities = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&apos;': "'",
  '&#039;': "'", // Single quote (') HTML entity
  '&reg;': '®',
  '&trade;': '™',
  '&copy;': '©',
  // Add more entities as needed
};

const replaceHtmlEntities = (text) => {
  // Create a regular expression with all entities joined by "|"
  const entityRegex = new RegExp(Object.keys(htmlEntities).join('|'), 'g');
  
  // Replace all occurrences of entities using the map
  return text.replace(entityRegex, (match) => htmlEntities[match]);
};

const DataTable = ({ tableData }) => {
  const filteredData = tableData.filter(item => 
    item.ConjugateSpecificity || 
    (item.sku && item.title && item.url)
  );

  return (
    <div className="container mt-4">
      <table className="table table-bordered">
        <thead className="thead-dark">
          {filteredData.length > 0 && (
            <tr>
              <th>Glycomatrix SKU</th>
              <th>Product</th>
              <th>Conjugate Type</th>
            </tr>
          )}
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{item.sku ? item.sku : ''}</td>
              <td>
                {item.title && item.url ? (
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {replaceHtmlEntities(item.title)}
                  </a>
                ) : ''}
              </td>
              <td>{item.ConjugateSpecificity || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
