import { useEffect, useState } from "react";
import axios from 'axios';
import toast from 'react-hot-toast';

const modalStyle = {
  display: 'block',
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0,0,0,0.4)',
  padding: '60px',
  borderRadius: '10px',
};

const modalContentStyle = {
  backgroundColor: '#fefefe',
  margin: '5% auto',
  padding: '20px',
  border: '1px solid #888',
  width: '80%',
  borderRadius: '10px'
};

const closeStyle = {
  color: '#000',
  float: 'right',
  fontSize: '28px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const radioContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px 0',
};

const radioStyle = {
  margin: '10px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const radioLabelStyle = {
  marginLeft: '10px'
};

const disabledButtonStyle = {
  backgroundColor: '#cccccc',
  cursor: 'not-allowed'
};

const checkboxContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginTop: '20px',
  flexWrap: 'wrap'
};

const checkboxStyle = {
  margin: '5px 10px',
};

const validationErrorStyle = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  bottom: 15
};

const textareaStyle = {
  width: '100%',
  minHeight: '100px',
  fontSize: '1rem',
  padding: '8px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  resize: 'vertical',
  marginTop: '20px',
  marginBottom: '20px',
};

const feedbackbutton = {
  background: '#da7700',
  color: '#ffffff',
  fontWeight: 600,
  width: '6rem',
  height: '2rem',
  fontSize: '1rem',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  marginRight: '1rem',
  marginTop: '5px'
};

const FeedbackModal = ({ content, showModal, closeModal, feedbackId, setShowFeedButton }) => {
  const [feedback, setFeedback] = useState('');
  const [radioValue, setRadioValue] = useState('');
  const [categories, setCategories] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    if (!showModal) {
      setFeedback('');
      setRadioValue('');
      setCategories([]);
      setValidationError('');
      setDisableSubmit(true);
    } else {
      setFeedback(content);
    }
  }, [showModal, content]);

  useEffect(() => {
    if (radioValue !== '' && feedback.trim() !== '') {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [radioValue, feedback]);

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setRadioValue(value);
    if (value === 'satisfied') {
      setCategories([]);
    }
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    setCategories((prevCategories) => {
      if (checked) {
        setValidationError('');
        return [...prevCategories, value];
      } else {
        return prevCategories.filter((category) => category !== value);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (radioValue === '' || feedback.trim() === '' || (radioValue === 'notsatisfied' && categories.length === 0)) {
      setValidationError('Please select at least one checkbox');
      return;
    }

    const formData = new FormData();
    formData.append('id', feedbackId);
    formData.append('comments', feedback);
    formData.append('iscorrect', radioValue);
    formData.append('additional_info', JSON.stringify(categories));

    try {
      const response = await axios.post('http://122.160.44.46:35110/api/feedback', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Feedback submitted successfully!');
      closeModal();
      setShowFeedButton(false);
      console.log(response);
    } catch (error) {
      toast.error('Failed to submit feedback. Please try again.');
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    showModal && (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <span style={closeStyle} onClick={closeModal}>
            &times;
          </span>
          <h2>Please provide your valuable Feedback for answer</h2>
          <hr />
          <form onSubmit={handleSubmit}>

            <div style={radioContainerStyle}>
              <div style={radioStyle}>
                <input
                  type="radio"
                  id="satisfied"
                  name="feedbackOption"
                  value="satisfied"
                  checked={radioValue === 'satisfied'}
                  onChange={handleRadioChange}
                  required
                />
                <label style={radioLabelStyle} htmlFor="satisfied">Satisfied</label>
              </div>
              <div style={radioStyle}>
                <input
                  type="radio"
                  id="notsatisfied"
                  name="feedbackOption"
                  value="notsatisfied"
                  checked={radioValue === 'notsatisfied'}
                  onChange={handleRadioChange}
                  required
                />
                <label style={radioLabelStyle} htmlFor="notsatisfied">Not Satisfied</label>
              </div>
            </div>
            {radioValue === 'notsatisfied' && (
              <div style={checkboxContainerStyle}>
                <label style={checkboxStyle}>
                  <input
                    style={{ margin: '10px' }}
                    type="checkbox"
                    value="incompleteanswer"
                    checked={categories.includes('incompleteanswer')}
                    onChange={handleCategoryChange}
                  />
                  Incomplete Answer
                </label>
                <label style={checkboxStyle}>
                  <input
                    style={{ margin: '10px' }}
                    type="checkbox"
                    value="noskureference"
                    checked={categories.includes('noskureference')}
                    onChange={handleCategoryChange}
                  />
                  No reference to SKUs
                </label>
                <label style={checkboxStyle}>
                  <input
                    style={{ margin: '10px' }}
                    type="checkbox"
                    value="genericAnswer"
                    checked={categories.includes('genericAnswer')}
                    onChange={handleCategoryChange}
                  />
                  Generic answer
                </label>
                <label style={checkboxStyle}>
                  <input
                    style={{ margin: '10px' }}
                    type="checkbox"
                    value="nopreciseanswer"
                    checked={categories.includes('nopreciseanswer')}
                    onChange={handleCategoryChange}
                  />
                  Not a precise answer
                </label>
                <label style={checkboxStyle}>
                  <input
                    style={{ margin: '10px' }}
                    type="checkbox"
                    value="wronganswer"
                    checked={categories.includes('wronganswer')}
                    onChange={handleCategoryChange}
                  />
                  Wrong answer
                </label>
              </div>
            )}
            <div>
              <textarea
                style={textareaStyle}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Enter your feedback..."
                required
              />
            </div>
            {validationError && <div className="feederror" style={validationErrorStyle}><p style={{ position: 'absolute', color: 'red' }}>{validationError}</p></div>}
            <button
              className="mt-4"
              style={disableSubmit ? disabledButtonStyle : feedbackbutton}
              type="submit"
              disabled={disableSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default FeedbackModal;
