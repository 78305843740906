import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchBar from './SearchBar';
import TextArea from './TextArea';
import logo from '../images/logo.png';
import EmailPopUp from './EmailPopUp';
import { RxHamburgerMenu } from "react-icons/rx";



const MainComponent = () => {
  const [inputValue, setInputValue] = useState('');
  const [userChatId, setuserChatId] = useState('');
  const [userId, setuserId] = useState('');
  const [responseText, setResponseText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [APIError, setAPIError] = useState('');
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [type, setType] = useState("");
  

  useEffect(() => {
    // Get input value from query string when component mounts
    const params = new URLSearchParams(window.location.search);
    const query = params.get('userMessage');
    const userChatSession = params.get('chat');
    const isAdmin = params.get('ByPassEmail');
    const Type = params.get('type');
    if(Type) {
      setType(Type);
    }
    if(isAdmin) {
      setIsAdminUser(true);
    }
    console.log("admin:", isAdminUser);

    if (userChatSession) {
      setCookie("chatSession", userChatSession, 1); // Set cookie for 1 day
    }
    const userChat = getCookieValue("chatSession");
    if (userChat) {
      setuserChatId(userChat);
    }
    // console.log(query);
    if (query) {
      setInputValue(query);
      handleSearch(query);
    }
    if (checkCookie() || isAdminUser) {
      setIsEmailSubmitted(true);
    } else {
      setIsEmailSubmitted(false);
    }
    if(userChat) {
      getUser(userChat);  
    }
  }, [isAdminUser]);// Empty dependency array ensures this effect runs only once, equivalent to componentDidMount
  const getUser = async (uuid) => {

    const formData = new FormData();
    //console.log(email);
    formData.append('uuid', uuid);

    try {
      const response = await axios.post(
        'http://122.160.44.46:35110/api/user_info',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const userData = response.data;
      setuserId(userData.email_id);
    } catch (error) {
      //setResponseText({ error: true });
    } finally {

    }

  };
  const handleSearch = async (query) => {
    if (!query.trim()) {
      setErrorMessage('Kindly provide your search query');
      return;
    }
    var userChat = getCookieValue("chatSession");
    if (checkCookie() || isAdminUser) {
      setIsEmailSubmitted(true);
      // const userChat = getCookieValue("chatSession");
      if (userChat) {
        setuserChatId(userChat);
      }
      // console.log("ankur---", userChat);
    } else {
      setIsEmailSubmitted(false);
      return;
    }
    
    setIsLoading(true);
    setResponseText('');
    if(userChat == undefined || userChat == "" && isAdminUser) {
      userChat = "UAT";
    }
    const formData = new FormData();
    formData.append('user_prompt', query);
    formData.append('chat', userChat);
    formData.append('type', type);
    // console.log("sirohi---", userChatId);

    try {
      const response = await axios.post(
        'http://122.160.44.46:35110/api/prompt_route',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const chatdata = response.data;
      // console.log("chatdata---------->",chatdata);

      setResponseText({
        content: chatdata?.Answer,
        uniqueId: chatdata?.id,
        doi: chatdata?.DOI,
        lectin: chatdata?.Lectin,
        products: chatdata?.Products,
      });
      setErrorMessage('');
    } catch (error) {
      setResponseText({ error: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage('');
  };

  const handleSearchButtonClick = () => {
    handleSearch(inputValue);
  };

  const handleRegenerate = () => {
    handleSearch(inputValue);
  };

  const checkCookie = () => {
    return document.cookie.split(';').some((item) => item.trim().startsWith('chatSession='));
  };

  const clearCookieSession = () => {
    document.cookie = "chatSession=; Max-Age=-99999999;";
    setIsEmailSubmitted(false);
    setuserId("");
    setResponseText('');
    setInputValue('');
  }

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const getCookieValue = (name) => {
    if (document.cookie.split('; ').find(row => row.startsWith(name))) {
      return document.cookie.split('; ').find(row => row.startsWith(name)).split('=')[1];
    }
  };
  const handleEmailSubmit = async (email) => {
    const formData = new FormData();
    formData.append('email', email);

    try {
      const response = await axios.post(
        'http://122.160.44.46:35110/api/uer_reg',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const chatdata = response.data;
      if(chatdata.uuid) {
        setCookie("chatSession", chatdata.uuid, 1); // Set cookie for 1 day
        setIsEmailSubmitted(true);
        setuserId(email);
        setuserChatId(chatdata.uuid);
        const origin = window.location.origin;
        const pathname = window.location.pathname;
        const newUrl = origin + pathname;

        window.location.replace(newUrl);
      }else if(chatdata.Error) {
        setAPIError(chatdata.Error);
      }else {
        setAPIError("There is an internal error.");
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setAPIError("Server is down for a while, Please try again");
    } finally {
      // setIsLoading(false);
    }

  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };


  return (
    <section className="body-main">
      {!isEmailSubmitted && <EmailPopUp APIerror={APIError} setAPIError={setAPIError} onSubmit={handleEmailSubmit} />}
      {userId && (
        <>
          <div className='email-show' style={{ position: 'absolute', top: '10px', right: '2rem' }}>
            <p>Hi {userId}</p>
            <button
              style={{ backgroundColor: '#f6931d', color: 'white', borderRadius: '5px', border: 'none' }}
              onClick={clearCookieSession}
            >
              Close session
            </button>
          </div>
          <button className="hamburger" onClick={toggleMenu}>
            <RxHamburgerMenu />
          </button>
          {isMenuVisible && (
            <div className="menu">
              <p>Hi {userId}</p>
              <button
                style={{ backgroundColor: '#f6931d', color: 'white', borderRadius: '5px', border: 'none' }}
                onClick={clearCookieSession}
              >
                Close session
              </button>
            </div>
          )}
        </>
      )}
      <div className="logo-top">
        <img src={logo} alt="logo" />
      </div>
      <SearchBar
        value={inputValue}
        setInputValue={setInputValue}
        onChange={handleInputChange}
        onClick={handleSearchButtonClick}
        disabled={isLoading}
        showPlaceholder={!inputValue}
      />

      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <TextArea responseText={responseText} regenerateHandler={handleRegenerate} isLoading={isLoading} isAdminUser={isAdminUser} />
    </section>
  );
};

export default MainComponent;
